import Typography from '@mui/material/Typography';
import React, { useState, } from 'react';
import { Image } from 'mui-image'
import main from "./main.png"
import CheckIcon from '@mui/icons-material/Check';
import Switch from '@mui/material/Switch';


const App = () => {
  const [language, setLanguage] = useState("sv");

  return (
    <html>
   <div className="switch">
      <Typography sx={{fontSize: "2.5rem"}}>🇸🇪</Typography>
      <Switch style={{ margin: 0, }}
                  value={language} onClick={() => {
                    if (language === "sv") setLanguage("en");
                    else setLanguage("sv");
                  }
                  } />
                  <Typography sx={{fontSize: "2.5rem"}}>🇬🇧</Typography>
      </div>  
      <header>         
        <div className="info">
          <Typography sx={{ fontSize: "3.5rem", textAlign: "center", textShadow: "0 0 0.03em snow, 0 0 0.03em silver, 0 0 0.03em royalblue" }}>
            DramaQueen</Typography>

          <Typography sx={{ fontSize: "1.1rem", textShadow: "0 0 0.05em snow, 0 0 0.05em silver, 0 0 0.05em royalblue" }}>
            <CheckIcon sx={{ color: "seagreen", fontSize: "1.5rem" }} /> {language === "sv" && "Tidrapportering för hela teamet"} {language === "en" && "Time reporting service for the entire team"}
            <br />
            <CheckIcon sx={{ color: "seagreen", fontSize: "1.5rem" }} /> {language === "sv" && "Rätt enheter automatiskt"} {language === "en" && "Correct units automatically"}
            <br />
            <CheckIcon sx={{ color: "seagreen", fontSize: "1.5rem" }} /> {language === "sv" && "Distribution av dagbesked"} {language === "en" && "Distribution of call sheets"}
            <br />
            <CheckIcon sx={{ color: "seagreen", fontSize: "1.5rem" }} /> {language === "sv" && "Arbetstider & adresser lättillgängligt"} {language === "en" && "Working hours & addresses easily accessable"}
            <br />
            <CheckIcon sx={{ color: "seagreen", fontSize: "1.5rem" }} /> {language === "sv" && "Använd av produktioner sedan feb 2024"} {language === "en" && "Used by drama productions since February 2024"}

          </Typography>
        </div>

        <Image src={main} width={250} />

      </header>

      <body>
        <div className="bulletpoints">
          <Typography sx={{ fontSize: "1rem", textShadow: "0 0 0.05em snow, 0 0 0.05em silver, 0 0 0.05em royalblue" }}>
            {language === "sv" && "Såhär går det till:"} {language === "en" && "How it works:"}
            <ol>
              <li>
                {language === "sv" && "Du beställer tjänsten för ditt projekt och väljer ett lösenord."}
                {language === "en" && "You apply for the service and choose a password."}

              </li>
              <li>
                {language === "sv" && "Ditt team joinar projektet i appen med lösenordet."}
                {language === "en" && "Your team joins the project in the app using the password."}
              </li>
              <li>
                {language === "sv" && "De tidrapporterar via appen. OB/ÖT-enheter räknas ut automatiskt."}
                {language === "en" && "They report their working hours using the app. OB and ÖT units are calculated automatically."}
              </li>
              <li>
                {language === "sv" && "Tidrapportansvarig hanterar rapporter i ett smidigt rutnät på webben."}
                {language === "en" && "Finance validates their reports in a smooth grid on the web."}
              </li>
              <li>
                {language === "sv" && "Skapa en utbetalningsspecifikation så ofta du behöver och samla allt i en enda excel."}
                {language === "en" && "Generate an excel for payout as often as you need, containing everyone's compensation."}
              </li>
            </ol>
         
            <ul>
              <li>
                {language === "sv" && "Oberoende av upplägg ni kör och lätt att komma igång. Ni slipper utskick av excelrapporter och ta emot dussintals excelfiler över mail varje vecka där folk har råkat fylla i fel enheter."}
                {language === "en" && "Independent of your production setup and easy to get started. You'll avoid sending weekly excel reports over mail and receiving dozens of reports that may contain incorrect units."}
                </li>
              <li>
                {language === "sv" && "Teamet får alla dagbesked, arbetstider och adresser snabbt tillgängligt. Du får tillgång till allas telefonnummer, adresser, matpreferenser, körkort och ICE-kontakter direkt."}
                {language === "en" && "The team will have access to all necessary info to start their day, in one place. You'll have access to everyone's information such as phone, address, food preferences and ICE-contacts immediately."}
              </li>
              <li>
                {language === "sv" && "Svensk, säker tjänst byggd med de största ramverken inom IT. Utvecklad av en ljusass."}
                {language === "en" && "Secure, Swedish service built using the most common frameworks in IT. The app can be toggled between English or Swedish. Developed by a lightning assistant."}
              </li>
              <li>
                {language === "sv" && "Enkel prisplan: 1250 SEK ex moms per vecka som behöver tidrapportering, dvs 1250 kr / inspelningsvecka. Upp till 100 teammedlemmar ingår. "}
                {language === "en" && "Simple price plan: 1250 SEK ex VAT per week needing time reporting. So, 1250 SEK / shooting week. Up to 100 team members is included in this rate. "}
                {language === "sv" && "Inga andra kostnader tillkommer. Ni får tillgång till tjänsten direkt efter beställning."}
                {language === "en" && "No other fees. You'll get access to the service immediately upon applying, meaning you can get started ahead of shooting."}
              </li>
              <li>
                {language === "sv" && "Ångerrätt: Om ni inte är nöjda inom två inspelningsveckor stryks fakturan."}
                {language === "en" && "If you are not satisfied within two shooting weeks for any reason at all, the entire fee is scrapped at no cost."}
              </li>
            </ul>
          </Typography>
        </div>
        <div className="bulletpoints" style={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: "1rem", textShadow: "0 0 0.05em snow, 0 0 0.05em silver, 0 0 0.05em royalblue" }}>
            {language === "sv" && "Tjänsten har använts och rekommenderas av en tre månader lång produktion för en av världens största klienter, med 70+ teammedlemmar från alla avdelningar på både lön och faktura. Skriv till dramaqueen@cybercine.se för all information och för kontaktuppgifter till de som använt tjänsten."}
            {language === "en" && "The service has been used as the main method of time reporting by a three month long production for one of the largest clients in the world, with over 70 team members on either salary or invoice. Send your enquiry to dramaqueen@cybercine.se for all information (there are two manuals with screenshots, one for the team and one for the prod office) and to get in touch with the people who have already used it!"}
          </Typography>
        </div>
      </body>

      <footer>
        <Typography sx={{ fontSize: "0.7rem", textShadow: "0 0 0.05em snow, 0 0 0.05em silver, 0 0 0.05em royalblue" }}>
          © 2024 Alex Stråe</Typography>
      </footer>
    </html>
  );
}

export default App;
